import {
  GET_NOTIFICATION_LIST_REQUEST,
  GET_NOTIFICATION_LIST_SUCCESS,
  OPEN_ADVERTISEMENT_REQUEST,
  OPEN_ADVERTISEMENT_SUCCESS,
  CLOSE_ADVERTISEMENT,
  RESET_NOTIFICATION,
} from '../actionConstants/advertisementConstants';

export const getNotificationListRequest = () => ({ type: GET_NOTIFICATION_LIST_REQUEST });
export const getNotificationListSuccess = ({ advertisementsList }) => ({
  type: GET_NOTIFICATION_LIST_SUCCESS,
  payload: { advertisementsList },
});
export const resetNotification = () => ({ type: RESET_NOTIFICATION });

export const openAdvertisementRequest = ({ callbackAction, callbackDelay } = {}) => ({
  type: OPEN_ADVERTISEMENT_REQUEST,
  payload: { callbackAction, callbackDelay },
});
export const openAdvertisementSuccess = ({ body, popupUrl, callbackAction }) => ({
  type: OPEN_ADVERTISEMENT_SUCCESS,
  payload: { body, popupUrl, callbackAction },
});
export const closeAdvertisement = () => ({ type: CLOSE_ADVERTISEMENT });
