import classNames from 'classnames';
import { memo, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CHANNEL_BROWSER } from 'shared-modules/constants';
import { LABEL_INDIVIDUAL_NOTICE, LABEL_NEWS, TAB_INDIVIDUAL_NOTICE, TAB_NEWS } from 'shared-modules/constants/message';
import {
  checkInitialDepositRequest,
  clearMessages,
  getUnreadCountRequest,
  setCurrentTab,
  startAutoRefreshMessage,
  stopAutoRefreshMessage,
} from 'shared-modules/redux/actions/messageActions';
import { Tabs } from '../../components';
import { Contents } from './Contents';
import styles from './message.module.scss';
import badgeStyles from './badge.module.scss';

const TAB_ITEM = (
  <div className={styles.tabItem}>
    <Contents />
  </div>
);

const classNameNormal = classNames(badgeStyles.tab);
const classNameActive = classNames(badgeStyles.tab, { [badgeStyles.active]: true });
const getClassName = (activeTab, tabName) => (activeTab === tabName ? classNameActive : classNameNormal);

export const Message = memo(() => {
  const dispatch = useDispatch();
  const { currentTab, unreadCountEvent, unreadCountIndividual } = useSelector((state) => state.message);

  const items = useMemo(() => {
    return [
      {
        id: TAB_NEWS,
        label: `${LABEL_NEWS}`,
        children: TAB_ITEM,
        badge: unreadCountEvent ? `${unreadCountEvent}` : undefined,
        badgeStyles,
        className: getClassName(currentTab, TAB_NEWS),
      },
      {
        id: TAB_INDIVIDUAL_NOTICE,
        label: `${LABEL_INDIVIDUAL_NOTICE}`,
        children: TAB_ITEM,
        badge: unreadCountIndividual ? `${unreadCountIndividual}` : undefined,
        badgeStyles,
        className: getClassName(currentTab, TAB_INDIVIDUAL_NOTICE),
      },
    ];
  }, [unreadCountEvent, unreadCountIndividual, currentTab]);

  const handleChange = useCallback(
    (activeKey) => {
      dispatch(setCurrentTab({ currentTab: activeKey }));
      dispatch(getUnreadCountRequest({ channel: CHANNEL_BROWSER }));
    },
    [dispatch],
  );

  useEffect(() => {
    dispatch(startAutoRefreshMessage());
    dispatch(getUnreadCountRequest({ channel: CHANNEL_BROWSER }));
    dispatch(checkInitialDepositRequest());
    return () => {
      dispatch(stopAutoRefreshMessage());
      dispatch(clearMessages());
    };
  }, [dispatch]);

  return <Tabs items={items} activeKey={currentTab} onChange={handleChange} />;
});
