import { call, put, select } from 'redux-saga/effects';
import { errorHandler } from '../errorSaga';
import {
  getMessagesRequestStartLoading,
  getMessagesRequestStopLoading,
  openMessageModal,
} from '../../actions/messageActions';
import { getMessages, markAsRead } from '../../../api/messageApi';
import { compareByIsImportant } from './common';
import { MESSAGE_TYPE } from '../../../constants';
import Logger from '../../../services/Logger';
import { getRecommendDataSuccess } from '../../actions/recommendActions';

function* callMarkAsOpenModal({ messageId }) {
  try {
    yield call(markAsRead, { messageId, requestBody: { isOpenModal: true } });
  } catch (e) {
    // throwしない
    Logger.error(e);
  }
}

export function* openMessageModalRequestHandler({ payload }) {
  try {
    const isAuthenticated = yield select((state) => state.auth.isAuthenticated);
    if (!isAuthenticated) {
      // 未ログイン時はスキップ
      return;
    }

    const { isIndividual, channel } = payload;
    yield put(getMessagesRequestStartLoading());
    const { data: messages } = yield call(getMessages, { isIndividual, channel });
    if (messages?.length) {
      const found = messages
        .filter(
          (m) =>
            !m.status?.readFlg &&
            m.type?.messageType === MESSAGE_TYPE.NOTIFICATION &&
            (m.isImportant || !m.status?.modalCount),
        )
        .sort(compareByIsImportant)
        .find(() => true);
      if (found) {
        yield put(openMessageModal({ message: found }));
        yield* callMarkAsOpenModal({ messageId: found.messageId });
        // 新規メッセージポップアップが出た場合はその後メッセージ画面に遷移する可能性があるため、
        // レコメンドモーダルが表示されないようにストアのレコメンドデータを削除する
        yield put(getRecommendDataSuccess({ recommendData: null }));
      }
    }
  } catch (error) {
    yield call(errorHandler, { error });
  } finally {
    yield put(getMessagesRequestStopLoading());
  }
}
