import { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useRangeOutList } from 'shared-modules/hooks/message';
import styles from './rangeOutContainer.module.scss';

const RangeOutContainer = memo(({ message }) => {
  const { messageId, linkUrl, status = {}, targetServices = [] } = message;
  const serviceValues = targetServices?.map(({ serviceId: serviceValue }) => serviceValue) ?? [];
  const { parameters = null } = status;
  const apGroups = useRangeOutList({ parameters, serviceValues });

  const handleClick = useCallback(() => {
    window.open(linkUrl, messageId);
  }, [linkUrl, messageId]);

  return (
    <div className={styles.container}>
      <div className={styles.topTitle}>レンジアウトをした自動売買グループがあります</div>
      <div className={styles.content}>
        <div className={styles.title}>注文の状況はホーム画面のポートフォリオ内にて確認できます。</div>
        <div className={styles.card}>
          <ul className={styles.list}>
            {apGroups.map(({ id, name }) => (
              <li key={id}>
                <div className={styles.name}>{name}</div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      {linkUrl && (
        <div className={styles.footer}>
          ※レンジアウトについては
          <div className={styles.link} onClick={handleClick} role="button" aria-hidden>
            こちら
          </div>
          をご確認ください
        </div>
      )}
    </div>
  );
});

RangeOutContainer.propTypes = {
  message: PropTypes.shape({
    messageId: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    action: PropTypes.string,
    linkUrl: PropTypes.string,
    startTime: PropTypes.string,
    endTime: PropTypes.string,
    status: PropTypes.shape({
      startTime: PropTypes.string,
      readFlg: PropTypes.bool,
      parameters: PropTypes.string,
    }),
    targetServices: PropTypes.arrayOf(
      PropTypes.shape({
        serviceId: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
};

export default RangeOutContainer;
